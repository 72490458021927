<template>
  <page-header-wrapper :title='this.$route.meta.title'>
    <a-card  :bordered='false'>
      <span slot='title'>
          <a-space>
           订单信息
          </a-space>
          <a-space style="margin-left: 20px" >
                  <div><a  @click='openDetail()'>购销记录</a></div>
          </a-space>
      </span>

      <span slot='extra'>
        <template>
           <a-space style="margin-left: 20px" >
                  <div>状态：<span style="font-size: large;color: #00A0E9">
                    <template v-if="getOrderType1(order) == '1'||getOrderType1(order) == '2'">
                      <span v-if="order.merchantOrderStatus=='1'">待支付</span>
                      <span v-if="order.merchantOrderStatus=='2'">已支付</span>
                    </template>
                    <template v-if="getOrderType1(order) == '3'">
                           <span v-if="order.status =='paying'">待支付</span>
                           <span v-else>已支付</span>
                    </template>
                  </span></div>

          </a-space>
        </template>
      </span>

      <a-descriptions :column="4">
        <a-descriptions-item label='订单号'>{{order.id}}</a-descriptions-item>
        <a-descriptions-item label='下单时间'>{{order.createTime}}</a-descriptions-item>
        <a-descriptions-item label='下单方式'>{{getOrderType(order)}}</a-descriptions-item>
        <a-descriptions-item  v-if="!order.backRecord" label='订单留言'><span style="color: #E62734;">{{ order.remark }}</span></a-descriptions-item>
        <a-descriptions-item v-else></a-descriptions-item>

        <a-descriptions-item label='购买人姓名'>
           <span v-if="order.memberShareholder=='1'" class="color-red">
            {{order.nickname}}
          </span>
          <span v-else>
             {{order.nickname}}
          </span>
        </a-descriptions-item>
        <a-descriptions-item label='购买人手机号'>{{order.memberMobile}}</a-descriptions-item>
        <a-descriptions-item label='购买人编码'>{{getUserCode(order.shareholder,order.memberType,order.memberLevel)}}</a-descriptions-item>
        <a-descriptions-item></a-descriptions-item>

        <a-descriptions-item label='推荐人姓名'>
           <span v-if="order.spreaderShareholder=='1'" class="color-red">
            {{order.spreaderMasterName}}
          </span>
          <span v-else>
             {{order.spreaderMasterName}}
          </span>
        </a-descriptions-item>
        <a-descriptions-item label='推荐人手机号'>{{order.spreaderMobile}}</a-descriptions-item>
        <a-descriptions-item label='推荐人编码'>{{getUserCode(order.shareholder,order.spreaderType,order.spreaderLevel)}}
<!--          -&#45;&#45;{{order.spreaderType}}&#45;&#45;{{order.spreaderLevel}}-->
        </a-descriptions-item>
        <a-descriptions-item label='分公司名称'>{{order.spreaderAreaName}}</a-descriptions-item>
<!--        memberAreaName-->

        <template v-if="order.quickOrder>0" >
          <a-descriptions-item label='预付金额' ><span class="color-red">{{order.quickAmount}}</span></a-descriptions-item>

            <template v-if="order.merchantMember==null || order.merchantMember==order.member ">
              <!--          只针对直付-->
                <a-descriptions-item label='结余金额' >{{order.quickAmount - order.amount}}</a-descriptions-item>
                <a-descriptions-item label='状态' >
                  <span v-if="order.quickOrder=='1'" class="color-red">待选购</span>
                  <span v-if="order.quickOrder=='2' && order.quickAmount == order.amount">已完成</span>
                  <span v-if="order.quickOrder=='2' && (order.quickAmount - order.amount)>0">
                    待退款 <a @click="initBackMoney(order)">手动退款</a>
                  </span>
                  <span v-else-if="order.quickOrder=='3'">已退款</span>
                </a-descriptions-item>
            </template>
          <template v-else>
            <!--          支付给商户就不显示-->
            <a-descriptions-item :span="2"></a-descriptions-item>
          </template>
          <a-descriptions-item label='实退金额' >{{order.quickRefundAmount}}</a-descriptions-item>

          <template v-if="order.merchantMember==null || order.merchantMember==order.member ">
            <a-descriptions-item label='退款方式' :span="4">
              <span v-if="order.quickRefundMethod=='score'">积分</span>
              <span v-else-if="order.quickRefundMethod=='money'">现金</span>
              <span v-else>未退款</span>
            </a-descriptions-item>
          </template>

        </template>

        <template v-if="!order.isShowOne">
          <!--          非后台录单显示-->
        <a-descriptions-item label='所属商户编码'>
              <span v-for="(item, index) in getAuthCodeList(order.authCode)" :key="index"
                    :style="{color: index == 1 ? '#1890ff' : ''}">{{ item }}</span>
            </a-descriptions-item>
            <a-descriptions-item label='负责人姓名'>{{order.masterName}}</a-descriptions-item>
            <a-descriptions-item label='商户名称'>{{order.merchantName}}</a-descriptions-item>
            <a-descriptions-item ></a-descriptions-item>
        </template>

        <template v-if="order.isShowOne">
          <!--            商户录单只显示订单金额-->
          <template v-if="order.merchantRecord">
                  <a-descriptions-item label='订单金额' :span="4">{{order.amount}}</a-descriptions-item>
          </template>
          <template v-else>
                  <!--           商户直付给公司，要取商户字段，-->
                  <template v-if="order.merchantMember==order.member">
                    <a-descriptions-item label='订单原始应付'>{{order.firstMerchantAmount}}</a-descriptions-item>
                  </template>
                  <!--           其他情况取用户购买字段-->
                  <template v-else>
                    <a-descriptions-item label='订单原始应付'>{{order.firstMemberAmount }}</a-descriptions-item>
                  </template>

                  <a-descriptions-item label='订单金额'>{{order.amount}}</a-descriptions-item>

                  <a-descriptions-item label='购买人应付' v-if="order.merchantMember==order.member">{{order.originalMerchantAmount}}</a-descriptions-item>
                  <a-descriptions-item label='购买人应付' v-else>{{order.amount}}</a-descriptions-item>

                  <a-descriptions-item v-if="order.merchantMember==order.member" label='积分抵扣'>{{order.merchantDiscount}}</a-descriptions-item>
                  <a-descriptions-item v-else label='积分抵扣'>{{order.memberDiscount}}</a-descriptions-item>
                  <a-descriptions-item v-if="order.merchantMember && order.merchantMember==order.member" label='转账优惠'>{{order.bankTransDiscount}}</a-descriptions-item>
                  <a-descriptions-item v-else></a-descriptions-item>

                  <template v-if="order.merchantMember==order.member">
                    <!--           商户直付给公司，要取商户字段，-->
                    <a-descriptions-item label='购买人实付'><span class="color-red">{{order.merchantAmount}}</span></a-descriptions-item>
                    <a-descriptions-item label='购买人支付方式'>{{order.merchantPaymentMethodName }}</a-descriptions-item>
                    <a-descriptions-item label='购买人支付时间' :span="2">{{order.merchantActualPaymentTime}}</a-descriptions-item>
                  </template>
                  <template v-else>
                    <!--           其他情况取用户购买字段-->
                    <a-descriptions-item label='购买人实付'><span class="color-red">{{order.consumeAmount}}</span></a-descriptions-item>
                    <a-descriptions-item label='购买人支付方式'>{{order.paymentMethodName }}</a-descriptions-item>
                    <a-descriptions-item label='购买人支付时间' :span="2">{{order.createTime}}</a-descriptions-item>
                  </template>
                </template>
          </template>

        <template v-if="order.isShowTwo">

          <template v-if="order.applyId">
            <!--          首先判断为批量兑换订单,不显示商户相关信息，只显示，商户名称和负责人-->
              <a-descriptions-item label='商户名称'>{{order.merchantName}}</a-descriptions-item>
              <a-descriptions-item label='负责人' :span="4">{{order.masterName}}</a-descriptions-item>
          </template>
          <template v-else>
            <!--         如果是其他订单，那么就显示所有商户信息-->
            <a-descriptions-item label='商户原始应付'>{{order.firstMerchantAmount}}</a-descriptions-item>
            <a-descriptions-item label='商户应付'>{{order.originalMerchantAmount}}</a-descriptions-item>
            <a-descriptions-item label='积分抵扣'>{{order.merchantDiscount}}</a-descriptions-item>
            <a-descriptions-item v-if="order.merchantMember && order.merchantMember!=order.member" label='转账优惠'>{{order.bankTransDiscount}}</a-descriptions-item>
            <a-descriptions-item v-else></a-descriptions-item>
            <a-descriptions-item label='商户实付'><span class="color-red">{{order.merchantAmount}}</span></a-descriptions-item>
          </template>


          <a-descriptions-item label='商户支付方式'>{{order.merchantPaymentMethodName}}</a-descriptions-item>
          <a-descriptions-item label='商户支付时间'>{{order.merchantActualPaymentTime}}</a-descriptions-item>
          <!--            merchantPaymentTime-->
          <a-descriptions-item label='所在地区'>{{order.area}}</a-descriptions-item>


        </template>

        <template v-if="order.isShowThree">
          <a-descriptions-item label='总销'>{{order.wealthQuotaMemberName}}</a-descriptions-item>
          <a-descriptions-item label='付款人姓名'>{{order.fuZeRen}}</a-descriptions-item>
          <a-descriptions-item label='转账凭证'>
            <img v-for="(item, index) in order.merchantPaymentProofList" :key="index" :preview="true" :src='item' style="margin-right: 20px;" width='100px' height="100px" />
          </a-descriptions-item>
          <a-descriptions-item label='转账方式'>{{order.bankTransBuyerName?'他人代付':'本人付'}}</a-descriptions-item>
        </template>

        <template>
          <a-descriptions-item label='购买协议' v-if="order.purchaseAgreementStatus=='1'">
            <img  :preview="true" :src='order.purchaseAgreementUrl' style="margin-right: 20px;" width='100px' height="100px" />
          </a-descriptions-item>
          <a-descriptions-item label='订货协议' v-if="order.orderAgreementStatus=='1'">
            <img  :preview="true" :src='order.orderAgreementUrl' style="margin-right: 20px;" width='100px' height="100px" />
          </a-descriptions-item>
        </template>


      </a-descriptions>

      <a-descriptions :column="4" v-if="order.auditStatus=='0'">
        <a-descriptions-item :span="4">
          <template v-if="(getOrderType1(order) == '3' && order.status=='paying') || ((getOrderType1(order) == '1'||getOrderType1(order) == '2') &&  order.merchantOrderStatus=='1')">

                <a-space >
                  <a-popconfirm title='确定收款吗？' ok-text='确认' cancel-text='取消' @confirm='initialEnsure(order)'>
                      <a-button type='primary'>
                        确认收款
                      </a-button>
                  </a-popconfirm>
                  <a-popconfirm title='确定取消吗？' ok-text='确认' cancel-text='取消' @confirm='handleDelete(order)'>
                      <a href='#'>取消订单</a>
                  </a-popconfirm>
                </a-space>
          </template>
        </a-descriptions-item>
      </a-descriptions>


    </a-card>

    <a-modal title='购销记录' :destroyOnClose='true' :maskClosable='true' width="70%"
             @cancel='()=>this.deliver1.visible=false' :footer="null"
             :visible='deliver1.visible' :confirmLoading='deliver1.loading'>
      <a-descriptions  :column="5">
          <a-descriptions-item label='单量'>{{ total }}单</a-descriptions-item>
          <a-descriptions-item label='应付金额'>{{ext1}}</a-descriptions-item>
          <a-descriptions-item label='使用积分'>{{ext2}}</a-descriptions-item>
          <a-descriptions-item label='实付金额' :span="2">{{ext3}}</a-descriptions-item>
      </a-descriptions>
      <a-form-model ref='form1' :model='deliver1.form'  layout='inline'>

        <a-form-model-item prop='start'>
          <a-range-picker
            v-model="dateArr"
            :format="[formatType, formatType]"
            :show-time="{
            hideDisabledOptions: true
          }"
            :disabled-date="disabledDate"
            @change="dateChange"
          />
        </a-form-model-item>

        <a-form-model-item prop='spreaderMobile'>
          <a-input v-model='queryParam1.spreaderMobile' style="width: 300px" placeholder="推荐人手机号" />
        </a-form-model-item>



        <a-form-model-item>
          <a-button type='primary' @click='handleSubmit1'>
            查询
          </a-button>
        </a-form-model-item>
        <a-form-model-item>
          <a-button @click='handleReset1'>
            重置
          </a-button>
        </a-form-model-item>
      </a-form-model>
      <s-table id="table1" ref='table1' rowKey='id' size='default' :columns='columns1' :pageSize='10' :scroll='{ x: 1500}' :data='loadData1'>
        <span slot='orderId' slot-scope='text, record'>
          <template>
              {{showCaseText(record)}}
          </template>
        </span>
      </s-table>
    </a-modal>

    <a-modal title='确认收款' :destroyOnClose='true' :maskClosable='true'
             :visible='deliver2.visible' @cancel='()=>this.deliver2.visible=false'
             ok-text='确认' cancel-text='取消' @ok='handleEnsure' :confirmLoading='deliver2.loading'>
      <a-form-model ref='form2' :model='deliver2.form' :labelCol='{ span: 6 }' :wrapperCol='{ span: 18 }'>
        <a-form-model-item label='订单号' prop='orderNo'>
          {{deliver2.form.orderNo}}
        </a-form-model-item>
        <a-form-model-item label='总销' prop='merchantAmount'>
          {{deliver2.form.totalSall}}
        </a-form-model-item>
        <a-form-model-item label='商户名称' prop='merchantName'>
          <a-input disabled v-model='deliver2.form.merchantName' />
        </a-form-model-item>
        <a-form-model-item label='应收金额' prop='originalMerchantAmount'>
          <a-input v-model='deliver2.form.originalMerchantAmount' @change="changeAmount"/>
        </a-form-model-item>
        <a-form-model-item label='剩余可用积分' prop='merchantDiscount'>
          <template>
            <a-input disabled v-model='deliver2.form.merchantDiscount' />
          </template>
        </a-form-model-item>

        <a-form-model-item label=' ' :colon="false">
          <a-radio-group v-model='deliver2.form.isUseds' @change="handleUseChange" :disabled="deliver2.form.orderDiscount==0 && deliver2.form.merchantPaymentMethod != ''">
            <a-radio value='0'>使用</a-radio>
            <a-radio value='1'>不使用</a-radio>
          </a-radio-group>
        </a-form-model-item>

        <a-form-model-item label='使用可用积分' prop='userCashBalance' v-if="deliver2.form.isUseds==='0' && !deliver2.form.orderDiscount && deliver2.form.merchantPaymentMethod=='' ">
          <a-input :disabled="!deliver2.form.canModUsesCashBalance" v-model='deliver2.form.userCashBalance' @change="handleChangeCashBalance" />
        </a-form-model-item>

        <template v-if="deliver2.form.orderDiscount">
          <a-form-model-item label='订单已用折扣' prop='orderDiscount' v-if="deliver2.form.isUseds==='0'">
            <a-input disabled  v-model='deliver2.form.orderDiscount' />
          </a-form-model-item>
        </template>

        <a-form-model-item label='实收金额' prop='merchantAmount'>
          <a-input disabled v-model='deliver2.form.merchantAmount' :class="font_class"/>
        </a-form-model-item>
        <a-form-model-item label=' ' prop='payMethod' :colon="false">
          <a-checkbox style="float: right;" @change="(e) => deliver2.form.checkStatus = e.target.checked ? 1 : 0">
            待核销
          </a-checkbox>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </page-header-wrapper>
</template>

<script>
import {STable, Ellipsis} from '@/components'
import {getMember, getMerchantMemberByMobile} from '@/api/member'
import {getMemberOrder, putAuditOrder, getAuditRecord, getMemberCash, refund, payMemberOrder} from '@/api/member-order'
import {getFuZeRen, getOrderType, getSpreaderMobileList, showOrderType} from '@/utils/util'
import moment from 'moment'
import {queryQuantityOrder, queryQuantityOrderV2} from "@/api/quantity";

// const formatType = 'YYYY-MM-DD HH:mm';
// const baseStart = moment().startOf('month').format(formatType);
// // const baseEnd = moment().format(formatType);
// const baseEnd = moment().startOf('day').format('YYYY-MM-DD')+ ' 23:59';
const formatType = 'YYYY-MM-DD';
const baseStart = moment().startOf('month').format(formatType);
const baseEnd = moment().format(formatType);

const columns1 = [
  {
    width: 150,
    title: '订单号',
    dataIndex: 'orderId',
    scopedSlots: { customRender: 'orderId' }
  },
  {
    width: 150,
    title: '应付金额',
    dataIndex: 'originalMerchantAmount',
    scopedSlots: { customRender: 'originalMerchantAmount' }
  },
  {
    width: 150,
    title: '使用积分',
    dataIndex: 'merchantDiscount',
    scopedSlots: { customRender: 'merchantDiscount' }
  },
  {
    width: 150,
    title: '实付金额',
    dataIndex: 'orderAmount',
    scopedSlots: { customRender: 'orderAmount' }
  },
  {
    width: 200,
    title: '下单时间',
    dataIndex: 'orderCreateTime',
    scopedSlots: { customRender: 'orderCreateTime' }
  },
  {
    width: 200,
    title: '推荐人手机号',
    dataIndex: 'spreaderMobile',
    scopedSlots: { customRender: 'spreaderMobile' }
  },
  {
    width: 150,
    title: '物流单号',
    dataIndex: 'logisticsNo',
    scopedSlots: { customRender: 'logisticsNo' }
  },
]

export default {
  name: 'AuditOrderEdit',

  data() {
    return {
      guiShuName:'',//归属名称
      ext4:0,//积分合计
      loading: false,
      profile: {},
      merchantProfile:{},
      order: {},
      record: [],
      font_class: "",
      yingFu: 0,
      formatType,
      columns1,
      dateArr: [baseStart, baseEnd],
      deliver1: { visible: false,
        form: {
          // start: baseStart,
          // end: baseEnd,
        }, loading: false,
        heard:{
        }
      },
      deliver2: { visible: false, form: {}, loading: false },
      queryParam1: {
        // merchantOrderStatus: 2,
        start: baseStart,
        end: baseEnd,
      },
      total: 0,
      ext1:0,//应付
      ext2:0,//使用积分
      ext3:0,//实付
      loadData1: parameter1 => {
        //第一步判断订单类型
        let memberId = '';
        let orderType = getOrderType(this.order);//1:用商，2：商直，3：用直
        if(orderType=='1') {//取商户
            memberId = this.order.merchantMember;
        }else if(orderType=='2'){//取商户
            memberId = this.order.merchantMember;
        }else if(orderType=='3'){//取用户
            memberId = this.order.member;
        }

        let queryParam = this.queryParam1;
        queryParam.id = memberId;
        queryParam.pageNum = parameter1.current;
        queryParam.pageSize = parameter1.size;
        return queryQuantityOrderV2(Object.assign(parameter1, queryParam))
          .then(datum => {
            this.total = datum.total;
            this.ext1 = datum.ext1||0;
            this.ext2 = datum.ext2||0;
            this.ext3 = datum.ext3||0;
            // this.merchantGxOrderCount = datum.total;
            // this.merchantGxOrderAmount = datum.records.length > 0 ? datum.records[0].merchantGxOrderAmount : 0;
            return datum
          })
      },


    }
  },
  components: {STable, Ellipsis},
  computed: {
    // id () {
    //   return this.$route.query.id;
    // },
  },
  activated() {
    this.id = this.$route.query.id
    this.handleInitial();
  },
  created() {
    this.id = this.$route.query.id;
    this.handleInitial()
  },
  methods: {
    getOrderType1(msg){
      return  getOrderType(msg);
    },
    dateChange() {
      this.queryParam1.start =  moment(this.dateArr[0]).format(this.formatType);
      this.queryParam1.end =  moment(this.dateArr[1]).format(this.formatType);
    },
    handleSubmit() {
      let discountDes = this.order.discountDes;
      // debugger;
      if (discountDes) {
        let isAdopt = false;
        let discountReg = [
          /^-?3\d+$/,
          /^-?4\d+$/,
          /^-?5\d+$/,
          /^-?7\d+$/,
          /^-?3\d+\/-?4\d+$/,
          /^-?3\d+\/-?5\d+$/,
          /^-?3\d+\/-?7\d+$/,
          /^-?4\d+\/-?5\d+$/,
          /^-?4\d+\/-?7\d+$/,
          /^-?5\d+\/-?7\d+$/,
          /^-?3\d+\/-?4\d+\/-?5\d+$/,
          /^-?3\d+\/-?4\d+\/-?7\d+$/,
          /^-?3\d+\/-?5\d+\/-?7\d+$/,
          /^-?4\d+\/-?5\d+\/-?7\d+$/,
          /^-?3\d+\/-?4\d+\/-?5\d+\/-?7\d+$/];
        for (const item of discountReg) {
          if (item.test(discountDes)) {
            isAdopt = true;
          }
        }
        if (!isAdopt) {
          this.$message.warning('商品折扣的格式必须为3X,4X,5X,3X/4X,3X/5X,3X/7X,4X/5X,4X/7X,5X/7X,3X/4X/5X,3X/4X/7X,3X/5X/7X,4X/5X/7X,3X/4X/5X/7/X，其中X必须为正整数');
          return;
        }
      }
      let params = {
        id: this.id,
        // quotaBalance: discountDes,
        // cashBalance: this.order.cashBalance,
      }

      this.loading = true;
      // let tempOrder = this.order;
      if(this.order.integralRewardList){
        params.integralRewardList = this.order.integralRewardList;
      }
      // debugger;
      putAuditOrder(params).then(result => {
        this.$message.success('操作成功')
        this.handleInitial();
        this.loading = false;
      }).catch(() => {
        this.loading = false;
      })
    },
    getAuthCodeList(authCode) {
      if (authCode) {
        let index = authCode.indexOf("-");
        if (index < 0) {
          index = authCode.length - 1;
        } else {
          index--;
        }
        let ownAuthCode = authCode.slice(index - 3, index + 1);
        if (ownAuthCode) {
          const authCodeList = authCode.split(ownAuthCode);
          authCodeList.splice(1, 0, ownAuthCode);
          return authCodeList;
        }
      }
      return [];
    },
    handleInitial() {
      let params = {
        id: this.id
      }
      getMemberOrder(params).then(result => {
        result.fuZeRen = getFuZeRen(result);//获取负责人

        result = showOrderType(result); //公共方法获取显示区域

        this.order = result;
        let tempMobile = result.member;

        getMember(Object.assign({id: tempMobile})).then(result => {
          this.profile = Object.assign({}, result);

          getMerchantMemberByMobile(Object.assign({mobile: this.profile.mobile})).then(result => {
            this.merchantProfile = Object.assign({}, result);

          }).catch(error=>{
            this.merchantProfile = undefined;
          })
        })
      })
      getAuditRecord(params).then(result => {
        this.record = result || [];
      })
    },
    getUserCode(shareholder,type,level){
      // debugger
      if(shareholder=='1'){
        //股东判断
        return '股东'
      }
      if(type=='store'){
        return '门店'
      }else if(type=='center') {
        // return '服务中心';
        return 'SF';
      }else if(type=='president') {
        // return '运营总裁';
        return 'SY'
      }else if(type=='normal'){
        if(level=='center'){
          // return '备选服务';
          return 'BF';
        } else if(level=='president'){
          // return '备选运营';
          return 'BY';
        }
        // return '用户';
        return 'Y';
      }else{
        return '';
      }
    },
    openDetail(mType){
      this.deliver1.loading = false;
      this.deliver1.visible = true;
    },
    disabledDate(current) {
      return current && current > moment().endOf('day');
    },
    handleSubmit1() {
      this.handleInitial();
      this.$refs.table1.refresh(true)

    },
    handleReset1() {
      this.$refs.form1.resetFields()
      this.$refs.table1.refresh(true)
    },
    showCaseText(record){
      // debugger;
      let text = "";
      if(record.businessName==='修改'||record.businessName==='受让'||record.businessName==='转让'){
        // debugger
        text = record.opUsername;
      }else{
        text = record.orderId;
      }
      // debugger;
      // text = text + record;
      return text;
    },
    changeSelect(){
        this.handleSubmit1()
    },
    initialEnsure(record) {
      let type = this.getOrderType1 (record);
      let mid = 1;
      if(type == '1' || type=='2'){//用商 or 商直 -> 取商户
        mid = record.merchantMember;
      }else if(type == '3'){// 用直 ->取用户
        mid = record.member;
      }
      this.spinning = true;
      getMember({ id: mid }).then(result => {
        let  profile = Object.assign({}, result);
          let backRecord = record.operatorNickname=='后台录单';//是否为后台录单
          let tempAmount = 0;//应收金额
          let tempDiscount = 0;//折扣金额


          if(type=='1'||type=='2'){//取商户
            tempAmount = parseFloat(record.originalMerchantAmount) || 0;//应收金额
            tempDiscount = parseFloat(record.merchantDiscount);
          }else{//取用户
            tempAmount = parseFloat(record.amount) || 0;//应收金额
            tempDiscount = parseFloat(record.memberDiscount);
          }
          let cashBalance = parseFloat(profile.cashBalance);
          // debugger
          let merchantAmount = 0;
          let canModUsesCashBalance = false;//是否允许修改用户积分
          let isUseds = 1;//默认选择不允许使用
          let userCashBalance = 0;//显示实际使用积分
          if(backRecord){
            // 2、后台给用户和商户录单，录单后需确认收款。此场景可正常修改。
            isUseds = '1';
            canModUsesCashBalance = true;
            merchantAmount = tempAmount;
          }else{
            //1、商户小程序支付给公司时选择转账或扫码支付，提交到后台确认收款。此场景不能修改使用积分。
            //首先判断订单是否有折扣，有折扣就直接使用订单折扣，没有就开放给用户使用折扣
            if(tempDiscount>0){
              //订单有折扣
              if (tempAmount > tempDiscount) {
                merchantAmount = tempAmount - tempDiscount;
              } else {
                merchantAmount = 0;//等于直接抵扣
              }
              userCashBalance = tempDiscount;
              isUseds = '0';
              canModUsesCashBalance = false;
            }else{
              //订单没有折扣，开放给用户使用帐户积分，可以选择，但不能修改
              isUseds = '1';
              canModUsesCashBalance = true;
              merchantAmount = tempAmount;
            }
          }

          let merchantPaymentMethod = record.merchantPaymentMethod;

          this.deliver2.form = {
            orderNo:record.id,
            id: record.id,
            totalSall:record.wealthQuotaMemberName?record.wealthQuotaMemberName:'无',//总销名称
            // payMethod: record.merchantPaymentMethod||record.paymentMethod,//||"bank_trans"
            merchantName: record.merchantName?record.merchantName:'无',
            merchantDiscount: cashBalance,//现金折扣
            originalMerchantAmount: tempAmount,
            tempOriginalMerchantAmount:tempAmount,
            merchantAmount: merchantAmount,//实收金额
            userCashBalance:0,//使用现金折扣初始化
            orderDiscount:userCashBalance,//订单原本折扣
            tempUserCashBalance:cashBalance,//帐户余额临时存放
            isUseds:isUseds,
            tempDiscount:tempDiscount,
            canModUsesCashBalance : canModUsesCashBalance,//是否允许使用
            merchantPaymentMethod:merchantPaymentMethod,//支付方式，这个为空才能允许使用折扣
          }
          this.tempDiscountUseValue = tempDiscount;
          this.deliver2.visible = true;
      });
    },
    /***************************************************************************************确认收款模块**************************************************************************************/
    handleEnsure() {
      this.$refs.form2.validate((result) => {
        if (result) {

          if(!this.deliver2.form.orderDiscount && this.deliver2.form.userCashBalance>this.deliver2.form.merchantDiscount){
            this.$message.error("不能大于剩余可用积分")
            return;
          }
          if(this.deliver2.form.isUseds=='0' && !this.deliver2.form.orderDiscount && !this.deliver2.form.userCashBalance){
            this.$message.error("使用积分不能为空")
            return;
          }

          let isUsed = this.deliver2.form.isUseds;

          this.deliver2.loading = true;
          let params = Object.assign({
            id: this.deliver2.form.id, payMethod: this.deliver2.form.payMethod,
            checkStatus: this.deliver2.form.checkStatus || 0,
            useCash:isUsed,
            originalMerchantAmount:this.deliver2.form.originalMerchantAmount,
          })


          if( isUsed =='0' && !this.deliver2.form.orderDiscount){
            //只有允许修改的时候才能传
            params.cashValue = this.deliver2.form.userCashBalance;
          }
          payMemberOrder(params).then(_result => {
            this.$message.success('操作成功')
            this.handleInitial(this.id);
            this.deliver2.visible = false
            this.deliver2.loading = false;
            this.order = {}

          }).catch(() => {
            this.deliver2.loading = false;
          })
        }
      })
    },
    changeAmount(e){
      const regex = /^[0-9]\d*$/;
      const value = event.target.value;
      if (!regex.test(value)) {
        debugger;
        this.deliver2.form.originalMerchantAmount = this.deliver2.form.tempOriginalMerchantAmount;
        let tempAmount = this.initValue() ;
        this.deliver2.form.merchantAmount =tempAmount;
        this.$message.info('只能输入正整数！');
        return;
      }

      let _form = this.deliver2.form;
      if(_form.isUseds=='1'){
        this.deliver2.form.userCashBalance = 0;
      }else{
        //勾选了使用
        if(this.deliver2.form.tempDiscount>0){
          this.deliver2.form.userCashBalance = this.deliver2.form.tempDiscount;
        }else{
          this.deliver2.form.userCashBalance = this.deliver2.form.tempUserCashBalance;
        }
      }
      let totalAmount = this.initValue();
      this.deliver2.form.merchantAmount = totalAmount;
    },
    handleUseChange(e){
      if(this.deliver2.form.orderDiscount==0 && this.deliver2.form.merchantPaymentMethod != ''){
        this.$message.error("由于本订单类型属于扫描或者转账支付，用户已经付过款了，不能使用现金折扣");
        return;
      }

      let _form = this.deliver2.form;
      if(_form.isUseds=='1'){
        this.deliver2.form.userCashBalance = 0;
        this.isChecked = 1;
      }else{
        //勾选了使用
        this.isChecked = 0;
        if(this.deliver2.form.tempDiscount>0){
          this.deliver2.form.userCashBalance = this.deliver2.form.tempDiscount;
        }else{
          this.deliver2.form.userCashBalance = this.deliver2.form.tempUserCashBalance;
        }
      }
      this.deliver2.form.merchantAmount = this.initValue();
    },
    //更改剩余积分时候触发
    handleChangeCashBalance(e){
      const regex = /^[0-9]\d*$/;
      const value = event.target.value;
      if (!regex.test(value)) {
        let amount = this.initValue();
        this.deliver2.form.merchantAmount = amount - this.deliver2.form.userCashBalance;
        this.deliver2.form.userCashBalance = "";
        this.$message.info('只能输入正整数！');
        return;
      }
      // debugger;
      if( this.deliver2.form.merchantDiscount < this.deliver2.form.userCashBalance){
        this.$message.info('积分不能大于可用积分');
        this.deliver2.form.userCashBalance = this.deliver2.form.tempUserCashBalance;

        let totalAmount = this.initValue();
        this.deliver2.form.merchantAmount = totalAmount;//更新应收金额
        return;
      }
      if(this.deliver2.form.originalMerchantAmount <= this.deliver2.form.userCashBalance){
        //如果可用积分大于实付价格，那么将使用可用积分设置为实收金额
        this.deliver2.form.userCashBalance = this.deliver2.form.originalMerchantAmount;
      }
      //计算实付金额
      this.deliver2.form.merchantAmount = this.deliver2.form.originalMerchantAmount - this.deliver2.form.userCashBalance;
    },
    //超过重制值
    initValue(){
      let totalAmount = 0
      this.deliver2.form.userCashBalance = this.deliver2.form.userCashBalance? this.deliver2.form.userCashBalance:0;
      if(this.deliver2.form.originalMerchantAmount > this.deliver2.form.userCashBalance){
        totalAmount = this.deliver2.form.originalMerchantAmount -  this.deliver2.form.userCashBalance;
        // this.deliver2.form.userCashBalance = totalAmount;
      }else{
        totalAmount = 0;
        this.deliver2.form.userCashBalance = this.deliver2.form.originalMerchantAmount;
      }
      return totalAmount;
    },
    getOrderType(order){
      let merchantMember = order.merchantMember;
      let member = order.member;
      let memberLevel = order.memberLvel;

      // 用户下单 有两种 用直 用商，商户下单只有商直
      // 首先判断有无商户号，
      //    —如果没有说明用直
      // 如果有判断跟member相不相等
      //  —相等：商直
      //   —不相等：用商
      debugger
      if(merchantMember && merchantMember !=member){// 用商 --用户下单，如果有（商户号）判断跟member不相等
        if(memberLevel=='vip'){
          return "会员下单";
        }else{
          return "用户下单";
        }
      }else if(merchantMember && merchantMember == member){//商户直付--商户下单 判断跟member相等
        return "商户下单";
      }else if(!merchantMember){//用户直付--商户下单 无（商户号）merchantMember
        if(memberLevel=='vip'){
          return "会员下单"
        }else{
          return "用户下单"
        }
      }
    }
    /***************************************************************************************确认收款模块**************************************************************************************/
  }
}
</script>
<style scoped>
.font-red {
  color: #E62734 !important;
}


</style>
